<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <Comeback />
        {{ $t('MedicineEquipmentSummary') }}
        <v-spacer></v-spacer>
        <v-btn color='primary' class='d-none d-md-block' @click='exportExcel'>
          {{ $t('excel') }}
        </v-btn>
        <v-btn color='primary' class='d-block d-md-none' icon fab outlined @click='exportExcel'>
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters :default-status='2' @onSendDate='addPayload'></DateFilters>
      <v-row class='px-2'>
        <v-col cols='12' md='6' class='py-0' lg='3'>
          <v-text-field
            v-model.trim='searchtext'
            dense
            outlined
            :label="`${$t('search')} ${$t('pressEnterForSearch')}`"
            @keypress.enter='addPayload(payload.start, payload.end)'
          >
          </v-text-field>
        </v-col>
        <v-col cols='12' md='6' class='py-0' lg='3'>
          <v-select
            v-model.trim='drug_category_id'
            :items='drugTypeList'
            :label="$t('category')"
            dense
            outlined
            :item-text='$i18n.locale'
            item-value='value'
            @change='addPayload(payload.start, payload.end)'
          ></v-select>
        </v-col>
        <v-col cols='12' md='6' class='py-0' lg='3'>
          <v-select
            v-model.trim='optionsSelection'
            :items='optionsList'
            :label="$t('type')"
            dense
            outlined
            item-text='text'
            item-value='value'
            @change='addPayload(payload.start, payload.end)'
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :loading='loading'
        disable-sort
        hide-default-footer
        item-key='number'
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.code`]='{ item }'>
          <router-link
            v-if="item.type == $t('receipt')"
            :to="{ name: 'order-detail', params: { id: item.id } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.code }}
          </router-link>
          <router-link
            v-if="item.type == $t('course_services')"
            :to="{ name: 'showDetailService', params: { id: item.id } }"
            class='font-weight-medium text-decoration-none'
            target='_blank'
          >
            {{ item.code }}
          </router-link>
          <router-link
            v-if="item.type == $t('transfer_out')"
            class='font-weight-medium text-decoration-none'
            :to="{ name: 'DetailTransferOut', params: { id: item.id } }"
          >
            {{ item.code }}
          </router-link>
        </template>
        <template v-slot:[`item.druglot_name`]='{ item }'>
          <span v-if='item.druglot_name'> {{ item.druglot_date | sumdate }} ({{ item.druglot_name
            }}) </span>
        </template>
        <template v-slot:[`item.name`]='{ item }'>
          <span :class="item.name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.name }}
          </span>
        </template>
        <template v-slot:[`item.date`]='{ item }'>
          {{ item.date | sumdatetime }}
        </template>
        <template v-slot:[`item.amount`]='{ item }'>
          <span :class="item.name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.amount }}
          </span>
        </template>
        <template v-slot:[`item.price`]='{ item }'>
          <span :class="item.name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.price }}
          </span>
        </template>
        <template v-slot:[`item.discount`]='{ item }'>
          <span :class="item.name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.discount }}
          </span>
        </template>
        <template v-slot:[`item.total`]='{ item }'>
          <span :class="item.name == $t('total') ? 'font-weight-bold primary--text' : ''">
            {{ item.total }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { ref, onMounted, watch } from '@vue/composition-api'
import { mdiCalendarBlank, mdiFileExcelOutline } from '@mdi/js'
import Comeback from '../Comeback.vue'
import { i18n } from '@/plugins/i18n'
import { reportDrug, removeComma } from '../useExcel'
import { sumdate, sumdatetime } from '@/plugins/filters'
import drugTypeForSearch from '@/@fake-db/data/drugTypeForSearch.json'
import DateFilters from '@/components/basicComponents/DateFilters.vue'

export default {
  components: {
    Comeback,
    DateFilters,
  },
  filters: {
    sumdate,
    sumdatetime,
  },
  setup() {
    const XLSX = require('xlsx')
    const moment = require('moment')
    const optionsList = ref([
      { text: i18n.t('all'), value: 0 },
      { text: i18n.t('receipt'), value: 1 },
      { text: i18n.t('use_service'), value: 2 },
      { text: i18n.t('transfer'), value: 3 },
    ])
    const optionsSelection = ref(0)
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('type'), value: 'type', width: 120 },
      { text: i18n.t('a_number'), value: 'code', width: 130 },
      { text: i18n.t('date'), value: 'date', width: 180 },
      { text: i18n.t('group'), value: 'drug_category_name', width: 50 },
      { text: `${i18n.t('Around the stock')}`, value: 'druglot_name', width: 180 },
      { text: i18n.t('durg_saler'), value: 'drugfrom_name', width: 180 },
      { text: i18n.t('code'), value: 'main_drug_id', width: 100 },
      { text: i18n.t('name'), value: 'name', width: 210 },
      {
        text: i18n.t('amount'),
        value: 'amount',
        width: 100,
        align: 'end',
      },
      { text: i18n.t('unit'), value: 'unit', width: 100 },
      {
        text: i18n.t('sale_price'),
        value: 'price',
        width: 210,
        align: 'end',
      },
      {
        text: i18n.t('discount'),
        value: 'discount',
        width: 210,
        align: 'end',
      },
      {
        text: i18n.t('net_amount'),
        value: 'total',
        width: 210,
        align: 'end',
      },
    ])
    const dataTableList = ref([])
    const options = ref({})
    const payload = ref({})
    const loading = ref(true)
    const header = ref('')
    const searchtext = ref('')
    const exportLoading = ref(false)
    const drugTypeList = ref(drugTypeForSearch.data)
    const drug_category_id = ref('')
    const dateStart = ref(moment(new Date()).format('YYYY-MM-DD'))
    const dateEnd = ref(moment(new Date()).format('YYYY-MM-DD'))
    const isShowDate1 = ref(false)
    const isShowDate2 = ref(false)
    const customerList = ref([])


    const addPayload = (start, end) => {
      dateStart.value = start
      dateEnd.value = end
      payload.value = {
        start: start,
        end: end,
        searchtext: searchtext.value,
        type: optionsSelection.value,
        drug_category_id: drug_category_id.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      let number = 1
      const { start, end } = payload
      const { data, customerData } = await reportDrug(payload)
      dataTableList.value = data
      customerList.value = customerData.map((item, i) => {
        item.customer_address = `${item.customer_address} ${item.customer_district}  ${item.customer_amphoe} ${item.customer_province} ${item.customer_zipcode}`
        delete item.customer_district
        delete item.customer_amphoe
        delete item.customer_province
        delete item.customer_zipcode
        delete item.drugfrom_name
        delete item.id
        delete item.druglot_date
        delete item.druglot_name
        delete item.customer_id_pri
        item.tags = item.tags ? String(item.tags) : ''
        return { number: number++, ...item }
      })
      header.value = `${i18n.t('report_drug_equipment_summary')}  ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
        'to',
      )} ${sumdate(end)}`
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      let dataExport = dataTableList.value.map(item => {
        delete item.id
        delete item.druglot_name
        delete item.druglot_date
        delete item.main_drug_id

        return item
      })
      dataExport = await removeComma(JSON.parse(JSON.stringify(dataExport)))
      const fileName = `${header.value}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  สรุปยอดขายยา/อุปกรณ์ */
      const Heading = [
        [`${header.value}`],
        [
          '#',
          i18n.t('type'),
          i18n.t('a_number'),
          i18n.t('date'),
          i18n.t('group'),
          `${i18n.t('Around the stock')}`,
          i18n.t('durg_saler'),
          i18n.t('name'),
          i18n.t('amount'),
          i18n.t('unit'),
          i18n.t('sale_price'),
          i18n.t('discount'),
          i18n.t('net_amount'),
        ],
      ]
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'type',
          'code',
          'date',
          'drug_category_name',
          'drug_id',
          'drugfrom_name',
          'name',
          'amount',
          'unit',
          'price',
          'discount',
          'total',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 12 } }]
      const wscols = [{ wch: 6 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, i18n.t('Medicine Equipment Summary').replace('/', '_'))

      /*  รายชื่อลูกค้าตามยอดขาย */
      const Heading2 = [
        [`${i18n.t('list_of_customers_by_sales')} `],
        [
          '#',
          i18n.t('a_number'),
          i18n.t('type'),
          i18n.t('category'),
          i18n.t('code'),
          i18n.t('drug_name'),
          i18n.t('lot'),
          i18n.t('customer_code'),
          i18n.t('name'),
          i18n.t('tel'),
          i18n.t('group_customers'),
          i18n.t('id_card'),
          i18n.t('gender'),
          i18n.t('email'),
          i18n.t('blood_type'),
          i18n.t('birthday'),
          i18n.t('old'),
          i18n.t('address'),
          i18n.t('tag'),
          i18n.t('amount'),
          i18n.t('unit'),
          i18n.t('discount'),
          i18n.t('price_unit'),
          i18n.t('total_price'),
          i18n.t('date'),
          i18n.t('user_is_list'),
        ],
      ]

      const ws2 = XLSX.utils.aoa_to_sheet(Heading2)
      XLSX.utils.sheet_add_json(ws2, await removeComma(JSON.parse(JSON.stringify(customerList.value))), {
        header: [
          'number',
          'code',
          'type',
          'drug_category_name',
          'main_drug_id',
          'name',
          'drug_id',
          'customer_id',
          'customer_name',
          'customer_tel',
          'customer_group_name',
          'customer_idcard',
          'customer_gender',
          'customer_email',
          'customer_blood',
          'customer_birthdate',
          'customer_age',
          'customer_address',
          'tags',
          'amount',
          'unit',
          'discount',
          'price',
          'total',
          'date',
        ],
        skipHeader: true,
        origin: -1,
      })

      const merge2 = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 18 } }]
      const wscols2 = [
        { wch: 6 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
      ]
      ws2['!merges'] = merge2
      ws2['!cols'] = wscols2

      /* พิมพ์ files */
      XLSX.utils.book_append_sheet(wb, ws2, `${i18n.t('customer')}`)
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      loading,
      dataTableList,
      columns,
      options,
      header,
      drugTypeList,
      searchtext,
      drug_category_id,
      dateStart,
      dateEnd,
      isShowDate1,
      payload,
      isShowDate2,
      optionsList,
      optionsSelection,
      addPayload,
      serachReport,
      exportExcel,
      mdiFileExcelOutline,
      icons: {
        mdiCalendarBlank,
      },
    }
  },
}
</script>
